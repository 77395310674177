<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#00FF00" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #000">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Add New Office </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center py-4>
      <v-flex xs12 lg10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">First Name</span>
            <v-text-field
              ref="firstname"
              color="#00FF00"
              placeholder="First Name"
              dense
              v-model="firstname"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Last Name</span>
            <v-text-field
              ref="lastname"
              color="#00FF00"
              placeholder="Last Name"
              dense
              v-model="lastname"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Username</span>
            <v-text-field
              ref="username"
              color="#00FF00"
              placeholder="Username"
              dense
              v-model="username"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Password</span>
            <v-text-field
              ref="password"
              color="#00FF00"
              placeholder="Password"
              type="password"
              dense
              v-model="password"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Phone No</span>
            <v-text-field
              ref="phone"
              color="#00FF00"
              placeholder="Phone No"
              type="number"
              dense
              v-model="phone"
              :error-messages="msg_phone"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Email Id</span>
            <v-text-field
              ref="email"
              color="#00FF00"
              placeholder="Email"
              dense
              v-model="email"
              :error-messages="msg_email"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Country</span>
            <v-text-field
              ref="country"
              color="#00FF00"
              placeholder="Country"
              dense
              v-model="country"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Location</span>
            <v-text-field
              ref="locationname"
              color="#00FF00"
              placeholder="Location"
              dense
              v-model="locationname"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">Gender</span>
            <v-select
              ref="gender"
              color="#00FF00"
              placeholder="Gender"
              dense
              v-model="gender"
              :items="genders"
              item-text="name"
              item-value="value"
              item-color="#00FF00"
            >
              <template v-slot:item="{ item }">
                <span class="text-left">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-flex>
          <v-flex xs12 sm6 text-left px-2>
            <span class="itemValue">User Type</span>
            <v-select
              ref="userType"
              color="#00FF00"
              placeholder="User Type"
              dense
              v-model="userType"
              :items="userTypes"
              item-text="name"
              item-value="value"
              item-color="#00FF00"
            >
              <template v-slot:item="{ item }">
                <span class="text-left">
                  {{ item.name }}
                </span>
              </template>
            </v-select>
          </v-flex>
        </v-layout>
        <v-layout wrap justify-center>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              outlined
              color="#FF1313"
              light
              :ripple="false"
              depressed
              @click="closeDialoge"
              class="itemValue"
            >
              Cancel
            </v-btn>
          </v-flex>
          <v-flex xs6 sm4 xl3 px-2>
            <v-btn
              block
              tile
              color="#00FF00"
              light
              :ripple="false"
              depressed
              @click="validateInput"
              class="itemValue"
            >
              Add user
            </v-btn>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  components: {},
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,

      firstname: null,
      lastname: null,
      username: null,
      password: null,
      phone: null,
      email: null,
      country: null,
      userType: "User",
      locationname: null,
      gender: "Male",
      genders: [
        { name: "Male", value: "Male" },
        { name: "Female", value: "Female" },
        { name: "Others", value: "Others" },
      ],
      userTypes: [
        { name: "User", value: "User" },
        { name: "Admin", value: "Admin" },
      ],

      msg_email: null,
      msg_phone: null,
    };
  },
  watch: {
    phone() {
      axios({
        method: "POST",
        url: "/user/check/phone",
        data: {
          phone: this.phone,
          id: null,
        },
      })
        .then((response) => {
          if (response.data.status == false) this.msg_phone = response.data.msg;
          else this.msg_phone = null;
        })
        .catch((err) => {
          this.msg = err;
          this.ServerError = true;
        });
    },
    email() {
      axios({
        method: "POST",
        url: "/user/check/email",
        data: {
          email: this.email,
          id: null,
        },
      })
        .then((response) => {
          if (response.data.status == false) this.msg_email = response.data.msg;
          else this.msg_email = null;
        })
        .catch((err) => {
          this.msg = err;
          this.ServerError = true;
        });
    },
  },
  methods: {
    closeDialoge() {
      this.$emit("stepper", {
        type: "Add",
        getData: false,
        addUser: false,
      });
    },
    validateInput() {
      if (!this.firstname) {
        this.msg = "Please Provide First Name";
        this.showSnackBar = true;
        return;
      } else if (!this.lastname) {
        this.msg = "Please Provide Last name";
        this.showSnackBar = true;
        return;
      } else if (!this.username) {
        this.msg = "Please Provide Username";
        this.showSnackBar = true;
        return;
      } else if (!this.password) {
        this.msg = "Please Provide Password";
        this.showSnackBar = true;
        return;
      } else if (!this.phone) {
        this.msg = "Please Provide Phone Number";
        this.showSnackBar = true;
        return;
      } else if (!this.email) {
        this.msg = "Please Provide Email";
        this.showSnackBar = true;
        return;
      } else if (!this.country) {
        this.msg = "Please Provide Country";
        this.showSnackBar = true;
        return;
      } else {
        this.addUser();
      }
    },
    addUser() {
      var userData = {};
      userData["firstname"] = this.firstname;
      userData["lastname"] = this.lastname;
      userData["username"] = this.username;
      userData["password"] = this.password;
      userData["phone"] = this.phone;
      userData["email"] = this.email;
      userData["country"] = this.country;
      userData["locationname"] = this.locationname;
      userData["gender"] = this.gender;
      userData["role"] = this.userType;
      axios({
        method: "post",
        url: "/user/createuser",
        data: userData,
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "User Created";
            this.showSnackBar = true;
            this.$emit("stepper", {
              type: "Add",
              getData: true,
              addUser: false,
            });
          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
  },
};
</script>